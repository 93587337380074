import React from "react";


// import Custom Components

import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import CartPage from "../components/cart";
import Seo from "../components/common/SEO";
function Cart() {

  return (
    <>
      <MainLayout>
        
      <Seo title="Owls- Shopping Cart" />
        <div className="main">

          <h1 className="d-none">
          Owls - Shopping Cart
          </h1>

          <div className="main">
          <div className="page-header text-center" >
            <div className="container">
                <h1 className="page-title">Shopping Cart<span style={{fontSize: '3rem'}}>Shop</span></h1>
            </div>
        </div>
            {/* <PageHeader title="Shopping Cart" subTitle="Shop" /> */}
            <Breadcrumb
              title="Shopping Cart"
              // parent1={["Shop", "shop/sidebar/list"]}
            />
            <CartPage />
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default Cart;
